/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { IssueInfo } from "./components/IssueInfo";

import { NewRequestInstructions } from "./components/NewRequestInstructions";
import { LookupIssueForm } from "./components/LookupIssueForm";
import { Button } from "@blueprintjs/core";


enum View {
  SubmitRequest,
  IssueLookup,
  RequestDetails,
}

export const Main = () => {

  const [issueId, setIssueId] = React.useState<string>(window.location.hash.substr(1))
  const [view, setView] = React.useState<View>(View.SubmitRequest);

  const listenToHash = () => {
    setIssueId(window.location.hash.substr(1));
  }

  React.useEffect(() => {
    window.addEventListener("hashchange", listenToHash)
    return () => {
      window.removeEventListener("hashchange", listenToHash);
    }
  }, [])

  const onSubmitNewClick = () => {
    window.location.hash = "";
    setView(View.SubmitRequest);
  };

  const onLookupClick = () => {
    window.location.hash = "";
    setView(View.IssueLookup);
  };


  React.useEffect(() => {
    if(issueId){
      setView(View.RequestDetails);
    }
  }, [issueId]);

  const getViewName = (view: View) => {
    switch (view) {
      case View.SubmitRequest:
        return "Submit a New Request";
      case View.IssueLookup:
        return "Lookup Request";
      case View.RequestDetails:
        return "Request Details";
    }
  };

  return (
    <div css={mainCss}>
      <div css={ticketInfoCss}>
        <h1>{getViewName(view)}</h1>

        {/** Logic to determine which component to show, based on our state  */}
        {issueId ? (
          <IssueInfo issueId={issueId} />
        ) : view === View.SubmitRequest ? (
          <NewRequestInstructions />
        ) : (
          <LookupIssueForm/>
        )}

        <div css={buttonContainerCss}>
          {/* Show the button to submit a new request if we're not on the Submit request page */}
          {view === View.RequestDetails || view === View.IssueLookup ? (
            <Button
              text="Submit New Request"
              onClick={() => onSubmitNewClick()}
              icon="add"
              minimal
            />
          ) : null}

          {/** Show the button to navigate to the lookup, if we're not on the lookup page */}
          {view !== View.IssueLookup ? (
            <Button
              text="Lookup Existing Request"
              onClick={onLookupClick}
              icon="search"
              minimal
            />
          ) : null}
        </div>
      </div>
      {/* <Button text={"add 50 issues"} onClick={() => fetch(`${getApiBaseUrl()}/issues/create-filler`)}/> */}
    </div>
  );
};

const mainCss = css`
  padding: 4px;
  max-width: 400px;
`;

const ticketInfoCss = css`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;
const buttonContainerCss = css`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  .bp3-button {
    flex: 1 1 auto;
    > span.bp3-button-text {
      color: white;
      font-size: 0.9em;
    }
  }
`;
