/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import {
  Button,
  Radio,
  RadioGroup,
  FormGroup,
  InputGroup,
  Intent,
  PopoverPosition,
  Icon,
} from "@blueprintjs/core";
import { Tooltip2, Classes } from "@blueprintjs/popover2";
import {
  ReportRequest,
  SingleMultiple,
  ReportType,
  ReportRequestKey,
  getReportPropDisplayName,
  getReportPropHelperText,
} from "../interfaces/ReportRequest";
import { Link } from "react-router-dom";
import { SendRequestView } from "./SendRequestView";

export const initialFields: ReportRequestKey[] = [
  "requestTitle",
  "requestorName",
  "programOffice",
  "contactPhone",
  "contactEmail",
  "backupContactName",
  "backupContactEmail",
];

export const sfarDependentFields: ReportRequestKey[] = [
  "partModel",
  "version",
  "notes",
];

export const NewRequestInstructions = () => {
  const [showForm, setShowForm] = React.useState<boolean>(true);

  const [reportRequest, setReportRequest] = React.useState<ReportRequest>({
    requestTitle: "",
    requestorName: "",
    programOffice: "",
    contactPhone: "",
    contactEmail: "",
    backupContactName: "",
    backupContactEmail: "",
    entityName: "",
    partModel: "",
    version: "-",
    notes: "-",
    singleOrMultiple: "multiple",
    typeOfRequest: null,
  });

  const fieldIsValid = (
    reportRequest: ReportRequest,
    fieldName: keyof ReportRequest
  ) => {
    const fieldHasValue: boolean = !!reportRequest[fieldName];

    const fieldIsSfar: boolean = sfarDependentFields
      .map((f) => f.toString())
      .includes(fieldName);
    const isSfarRequest: boolean = reportRequest.typeOfRequest === "SFAR";

    const singleOnlyFields: ReportRequestKey[] =
      sfarDependentFields.concat("entityName");
    const fieldIsSingleOnly: boolean = singleOnlyFields
      .map((f) => f.toString())
      .includes(fieldName);

    const isMultiRequest: boolean =
      reportRequest.singleOrMultiple === "multiple";

    // const optionalFields: ReportRequestKey[] = ["notes"];
    // const fieldIsOptional: boolean = optionalFields
    //     .map((f) => f.toString())
    //     .includes(fieldName);

    const isValid =
      fieldHasValue ||
      //fieldIsOptional ||
      (isMultiRequest && fieldIsSingleOnly) ||
      (!isSfarRequest && fieldIsSfar);

    return isValid;
  };

  const invalidFieldNames: string[] = React.useMemo(() => {
    let invalidFieldNames: string[] = [];

    for (let key in reportRequest) {
      if (!fieldIsValid(reportRequest, key as keyof ReportRequest)) {
        invalidFieldNames.push(
          getReportPropDisplayName(key as keyof ReportRequest)
        );
      }
    }

    return invalidFieldNames;
  }, [reportRequest]);

  const createTextInputField = (propName: ReportRequestKey) => {
    return (
      <FormGroup
        key={propName}
        label={getReportPropDisplayName(propName)}
        helperText={getReportPropHelperText(propName)}
      >
        <InputGroup
          //placeholder={getReportPropDisplayName(propName)}
          value={reportRequest[propName]}
          onChange={(e) => {
            let updatedReportRequest: ReportRequest = { ...reportRequest };
            updatedReportRequest[propName] = e.currentTarget.value;
            setReportRequest(updatedReportRequest);
          }}
        />
      </FormGroup>
    );
  };

  return (
    <div css={newRequestCss}>
      {showForm ? (
        <div className="questions">
          <RadioGroup
            label="Are you requesting a single report, or multiple reports?"
            selectedValue={reportRequest.singleOrMultiple || ""}
            onChange={(event) =>
              setReportRequest({
                ...reportRequest,
                singleOrMultiple: event.currentTarget.value as SingleMultiple,
              })
            }
            inline
          >
            <Radio label="Single" value="single" />
            <Radio label="Multiple" value="multiple" />
          </RadioGroup>
          <div className="multi-item-list-instructions">
            {reportRequest.singleOrMultiple === "multiple" ? (
              <em>
                For a multi-item list, please attach either your TAC RFI or the
                CSCT RFI to the request. Either RFI is acceptable. You can
                download this{" "}
                <Link
                  to="/files/SupplyChainResearchRequestForm.xlsx"
                  target="_blank"
                  download
                >
                  RFI template
                </Link>{" "}
                as an example.
              </em>
            ) : null}
          </div>
          <RadioGroup
            label={
              <div>
                Type of Request
                <Tooltip2
                  content={
                    <div>
                      <strong>Short Form Analytic Reports</strong> are typically
                      10-25 pages <br /> covering information pertaining to
                      hardware, software, <br />
                      and corporate.
                      <br />
                      <strong>Short Corporate</strong> reports are 1-2 pages
                      covering
                      <br />
                      overview information specific to a requested entity.
                    </div>
                  }
                  className={Classes.TOOLTIP2_INDICATOR}
                  position={PopoverPosition.BOTTOM}
                >
                  <Icon icon="info-sign" />
                </Tooltip2>
              </div>
            }
            name="TypeOfRequest"
            selectedValue={reportRequest.typeOfRequest || ""}
            onChange={(event) =>
              setReportRequest({
                ...reportRequest,
                typeOfRequest: event.currentTarget.value as ReportType,
              })
            }
            inline
          >
            <Radio label="SFAR" value="SFAR" />
            <Radio label="Short Corporate" value="Short Corporate" />
          </RadioGroup>
          {initialFields.map((propName) => createTextInputField(propName))}
          {reportRequest.singleOrMultiple === "single"
            ? createTextInputField("entityName")
            : null}
          {reportRequest.singleOrMultiple === "single" &&
          reportRequest.typeOfRequest === "SFAR" ? (
            <React.Fragment>
              {sfarDependentFields.map((propName) =>
                createTextInputField(propName)
              )}
            </React.Fragment>
          ) : null}
        </div>
      ) : (
        <SendRequestView reportRequest={reportRequest} />
      )}
      <div css={buttonContainerCss}>
        <Button
          text={showForm ? "Next" : "Back"}
          onClick={() => setShowForm(!showForm)}
          intent={Intent.PRIMARY}
          rightIcon={showForm ? "caret-right" : null}
          icon={showForm ? null : "caret-left"}
          disabled={!!invalidFieldNames.length}
          className={showForm ? "next" : "back"}
        />
        <div className="validation">
          {invalidFieldNames.length
            ? "MISSING: " + invalidFieldNames.join(", ")
            : ""}
        </div>
      </div>
    </div>
  );
};

const newRequestCss = css`
  display: flex;
  width: 375px;
  flex-direction: column;
  .questions {
    margin-top: 8px;
    .bp3-label {
      margin-bottom: 4px;
    }
    .bp3-form-helper-text {
      color: white;
      font-style: italic;
    }
    .multi-item-list-instructions {
      margin-bottom: 24px;
      a {
        font-size: 1.2em;
      }
    }
    .bp3-form-group {
      margin-top: 12px;
    }
    .bp3-popover2-target {
      margin-left: 8px;
      border-bottom: none;
      display: inline;
      color: #eaeaea;
    }
  }
`;

const buttonContainerCss = css`
  height: 40px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  button.next {
    align-self: end;
  }
  button.back {
    align-self: baseline;
  }
  .validation {
    font-size: 0.8em;
    text-align: end;
    display: none;
  }
  &:hover .validation {
    display: block;
  }
`;
