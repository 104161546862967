/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { FormGroup, InputGroup, Button, Intent } from "@blueprintjs/core";

export const LookupIssueForm = () => {
  const [formIssueId, setFormIssueId] = React.useState<string>("");
  const [isTouched, setIsTouched] = React.useState<boolean>(false);

  const onSubmit = () => {
    setIsTouched(true);
    window.location.hash = `${formIssueId}`;
    setFormIssueId("");
  };

  return (
    <div css={formCss}>
      <FormGroup
        label="Enter the Request ID you recieved by email"
        intent={isTouched && !formIssueId ? Intent.DANGER : Intent.NONE}
        helperText={isTouched && !formIssueId ? "Please enter an Id" : ""}
      >
        <InputGroup
          id="issue-id"
          placeholder="Request ID (ex: 451)"
          value={formIssueId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsTouched(true);
            setFormIssueId(event.target.value);
          }}
          // onSubmit={onSubmit} //not working for Enter.
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === "Enter") onSubmit();
          }}
          intent={isTouched && !formIssueId ? Intent.DANGER : Intent.NONE}
        />
        <Button
          text={"View Status"}
          onClick={onSubmit}
          css={viewButtonCss}
          icon="search"
          type="submit"
        />
      </FormGroup>
    </div>
  );
};

const formCss = css`
  width: 400px;
`;

const viewButtonCss = css`
  margin-top: 8px;
  margin-bottom: 12px;
  float: right;
`;
