/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { DateTime } from "luxon";
import { Issue } from "../interfaces/Issue";
import { Button, PopoverPosition } from "@blueprintjs/core";
import { Tooltip2, Classes } from "@blueprintjs/popover2";
import { getApiBaseUrl } from "../App";

export const IssueInfo = ({ issueId }: { issueId: string }) => {
  const [issue, setIssue] = React.useState<Issue>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    console.log({ issueId });
    issueId && setIsLoading(true);
    fetch(`${getApiBaseUrl()}/issue/${issueId}`)
      .then((res) => res.json())
      .then((data) => {
        return setIssue(data);
      })
      .finally(() => setIsLoading(false));
  }, [issueId]);

  const issueStatus = React.useMemo(() => {

    return issue && 
      issue.state === "closed" ? "Closed"
      : issue?.labels && issue.labels.length
      ? issue.labels
          .filter((label) => label.split("/")[0] === "Status")
          .map((label) => label.split("/").pop())
          .join(", ") //we should only ever have one issue, but just in case there's more than one
      : "Received";
  }, [issue]);

  return (
    <div css={issueInfoCss}>
      {issue?.id ? (
        <React.Fragment>
          <div>Details for Request #{issue.id}</div>

          <div css={issueDetailsCss}>
            <div
              className={`status ${issueStatus
                .replace(/\s/g, "")
                .toLowerCase()}`}
            >
              {issueStatus === "Information Needed" ? (
                <Tooltip2
                  content="You should have received an email requesting additional information."
                  className={Classes.TOOLTIP2_INDICATOR}
                  position={PopoverPosition.BOTTOM}
                >
                  <Button icon="info-sign" minimal small />
                </Tooltip2>
              ) : null}

              {issueStatus}
            </div>
            <div>
              Last updated: {DateTime.fromISO(issue.updatedAt).toLocaleString()}
            </div>
            <div>
              Request Submitted:{" "}
              {DateTime.fromISO(issue.createdAt).toLocaleString()}
            </div>
          </div>
        </React.Fragment>
      ) : isLoading ? (
        <div><em>loading...</em></div>
      ) : (
        <div>
          The ticket with the ID "{issueId}" was not found.
        </div>
      )}
      <div className="questions">
        For questions regarding your request, please respond to the email
        associated with the ticket.
      </div>
    </div>
  );
};

const issueInfoCss = css`
  display: flex;
  flex-direction: column;
  width: 400px;
  .questions {
    margin-top: 4px;
  }
`;

const issueDetailsCss = css`
  position: relative;
  > div {
    padding: 8px;
  }
  border: 1px solid gray;
  border-radius: 3px;
  margin-top: 8px;
  background-color: #161616;
  .status {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: bold;
    max-width: 150px;
    text-align: right;
    &.received {
      color: #00b2fe;
    }
    &.pendingapproval {
      color: #ff8310;
    }
    &.inprogress {
      color: #e5ca01;
    }
    &.closed {
      color: #c090ff;
    }
    &.informationneeded,
    &.informationneeded span.bp3-icon svg {
      color: #ff2a2a;
    }
    &.informationneeded .bp3-tooltip2-indicator {
      border: unset; /* there's a weird bug when Tooltip is around the button, causing a bottom border */
      button:hover {
        background: unset;
        span.bp3-icon svg {
          color: #b31f1f;
        }
      }
    }
    &.delivered {
      color: #82ff00;
    }
  }
`;
