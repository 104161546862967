/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import {
  ReportRequest,
  getReportPropDisplayName,
  ReportRequestKey,
} from "../interfaces/ReportRequest";
import { Button, Toaster, Position, Intent } from "@blueprintjs/core";
import { initialFields, sfarDependentFields } from "./NewRequestInstructions";

interface Props {
  reportRequest: ReportRequest;
}

const AppToaster = Toaster.create({
  className: "recipe-toaster",
  position: Position.BOTTOM,
});

export const SendRequestView = ({ reportRequest }: Props) => {
  //scrm-requests@pnnl.gov
  const serviceDeskEmail: string =
    "scrmgitlab-incoming+report-requests-report-queue-2-issue-@pnnl.onmicrosoft.com";

  const getEmailField = React.useCallback(
    (fieldName: keyof ReportRequest) => {
      return `${getReportPropDisplayName(fieldName as ReportRequestKey)}: ${
        reportRequest[fieldName] || "*RESPONSE NEEDED*"
      }`;
    },
    [reportRequest]
  );

  const emailFields: string[] = React.useMemo(() => {
    let fields: string[] = [];

    if (reportRequest.singleOrMultiple === "multiple") {
      fields.push(
        "**For multiple requests, please attach either your TAC RFI or the CSCT RFI to this email**"
      );
    }

    for (const fieldName of initialFields) {
      fields.push(getEmailField(fieldName));
    }
    fields.push(getEmailField("typeOfRequest"));

    if(reportRequest.singleOrMultiple === "single"){
        fields.push(getEmailField("entityName"));
    }

    if (
      reportRequest.typeOfRequest === "SFAR" &&
      reportRequest.singleOrMultiple === "single"
    ) {
      for (const fieldName of sfarDependentFields) {
        fields.push(getEmailField(fieldName));
      }
    }

    return fields;
  }, [reportRequest, getEmailField]);

  const copyFieldsClick = React.useCallback(() => {
    navigator.clipboard.writeText(emailFields.join("\r\n\r\n"));
    AppToaster.show({
      message: "Copied! Now paste the text into a new email.",
      intent: Intent.SUCCESS,
      icon: "duplicate",
      timeout: 10000,
    });
  }, [emailFields]);

  return (
    <div css={sendRequestCss}>
      <div className="email-options">
          Requests are submitted through email.
        <div className="primary-option" >
          <a
            href={`mailto:${serviceDeskEmail}?subject=${
              reportRequest.requestTitle || "*SUBJECT NEEDED*"
            }&body=${emailFields.join("%0A%0A")}`}
          >
            CLICK HERE
          </a>{" "}
          to open an email
          {
              reportRequest.singleOrMultiple === "multiple"
              ? ". Please attach your RFI before sending."
              : " that is all ready to send."
          }
        </div>
        <div className="alternative-option">
          Alternatively, open an new email addressed to <strong>{serviceDeskEmail}</strong>. Copy the text below for the body and use the Request Title as your subject.
        </div>

        <div css={copyFieldsCss}>
          <div>
            {emailFields.map((fieldName) => {
              if (fieldName) {
                return <div key={fieldName}>{fieldName}</div>;
              } else {
                return <br />;
              }
            })}
          </div>
          <Button
            text="Copy"
            icon="duplicate"
            css={copyButtonCss}
            onClick={() => copyFieldsClick()}
          />
        </div>
      </div>
    </div>
  );
};

const sendRequestCss = css`
  .email-options {
    .primary-option{
        text-align: center;
        margin-top: 12px;
        font-size: 1.3em;
        a {
            font-size: 1.8em;
            display: block;
        }
    }
    .alternative-option {
      margin-top: 12px;
      font-style: italic;
    }
  }
`;

const copyFieldsCss = css`
  > div {
    padding: 8px;
  }
  height: 250px;
  border: 1px solid gray;
  border-radius: 3px;
  margin-top: 8px;
  background-color: #161616;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const copyButtonCss = css`
  width: 100%;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
`;
