export type SingleMultiple = "single" | "multiple" | null;
export type ReportType = "SFAR" | "Short Corporate" | null;

const reportFields = [
  "requestTitle",
  "requestorName",
  "programOffice",
  "contactPhone",
  "contactEmail",
  "backupContactName",
  "backupContactEmail",
  "entityName",
  "partModel",
  "version",
  "notes",
] as const;

export type ReportRequestKey = typeof reportFields[number];

export interface ReportRequest extends Record<ReportRequestKey, string> {
  singleOrMultiple?: SingleMultiple;
  typeOfRequest?: ReportType;
}

export const getReportPropDisplayName = (fieldName: keyof ReportRequest) => {
  switch (fieldName) {
    case "requestTitle":
      return "Request Title";
    case "requestorName":
      return "Requestor Name";
    case "programOffice":
      return "Program Office";
    case "contactPhone":
      return "Contact Phone";
    case "contactEmail":
      return "Contact Email";
    case "backupContactName":
      return "JWICS Contact Name";
    case "backupContactEmail":
      return "JWICS Contact Email";
    case "singleOrMultiple":
      return "Single or Multiple";
    case "typeOfRequest":
      return "Type of Request";
    case "entityName":
      return "Entity Name (Company or Person)";
    case "partModel":
      return "Part/Model";
    case "version":
      return "Version (if software)";
    case "notes":
      return "Notes (optional)";
  }
};

export const getReportPropHelperText = (fieldName: keyof ReportRequest) => {
  switch (fieldName) {
    case "requestTitle":
      return "The Title is a name used to identify your request";
    case "requestorName":
    case "programOffice":
    case "contactPhone":
    case "contactEmail":
    case "backupContactName":
    case "backupContactEmail":
    case "singleOrMultiple":
    case "typeOfRequest":
    case "entityName":
    case "partModel":
    case "version":
    case "notes":
      return "";
  }
};
