/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Main } from "./Main";
import logo from "./assets/SSCLogo.svg";
import title from "./assets/SCRMRequestSystem.svg";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "./App.css";

function App() {
  const routeRoute: string = "/";
    //process.env.NODE_ENV === "production" ? "/SRS/demo/scrmRequests/" : "/";

  return (
    <BrowserRouter basename={routeRoute}>
      <div css={appCss}>
        <img src={title} css={titleCss} alt="SCRM Requests" />
        <img src={logo} css={logoContainerCss} alt={"Space Systems Command"} />
        <Main />
      </div>
    </BrowserRouter>
  );
}

export default App;

export const getApiBaseUrl = () => {
  return process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_API : process.env.REACT_APP_PROD_API;
}

const appCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  a {
    color: #f9be3c !important;
  }
  position: relative;
`;

const titleCss = css`
  margin-top: 32px;
`;

const logoContainerCss = css`
  height: 35vmin;
  width: 20vmin;
`;
